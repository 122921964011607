/*-------------------------
   Pricing
-------------------------*/
.pricing-table {
    background-color: var(--color-white);
    padding: 40px;
    border-radius: 30px;
    position: relative;
    margin-bottom: 30px;
    transition: var(--transition);
    @media #{$lg-layout} {
        padding: 40px 15px;
    }
    @media #{$large-mobile} {
        padding: 40px 30px;
    }
    .pricing-header {
        .title {
            font-size: 40px;
            margin-bottom: 8px;
            letter-spacing: -0.045em;
            transition: var(--transition);
            @media #{$smlg-device} {
                font-size: 36px;
            }
            @media #{$large-mobile} {
                font-size: 30px;
            }
        }
        .subtitle {
            font-size: 14px;
            color: var(--color-text-dark);
            transition: var(--transition);
        }
        .price-wrap {
            margin: 40px 0 30px;
            font-family: var(--font-primary);
            display: flex;
            align-items: center;
            .amount {
                font-size: 64px;
                line-height: 1;
                color: var(--color-primary);
                font-weight: 500;
                letter-spacing: -0.045em;
                transition: var(--transition);
                @media #{$smlg-device} {
                    font-size: 50px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                }
            }
            .duration {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-gray-2);
                margin-left: 8px;
                transition: var(--transition);
            }
            .monthly-pricing {
                display: none;
            }
        }
    }
    .pricing-body {
        margin-top: 40px;
        li {
            padding: 6px 0;
            color: var(--color-gray-2);
            padding-left: 34px;
            position: relative;
            transition: var(--transition);
            &:before {
                content: url('../../media/others/box-1.png');
                position: absolute;
                top: 5px;
                left: 0;
            }
            &.yes {
                &:before {
                    content: url('../../media/others/box-yes.png');

                }
            }
        }
    }
    .star-icon {
        height: 48px;
        width: 48px;
        line-height: 48px;
        text-align: center;
        color: var(--color-primary);
        background-color: var(--color-accent1);
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: 10px;
    }
    &:hover, &.active {
        background-color: var(--color-primary);
        .pricing-header {
            .title {
                color: var(--color-white);
            }
            .subtitle {
                color: var(--color-white);
            }
            .price-wrap {
                .amount {
                    color: var(--color-white);
                }
                .duration {
                    color: var(--color-white);
                }
            }
            .axil-btn {
                background-color: var(--color-white);
                border-color: var(--color-white);
                color: var(--color-primary);
            }
        }
        .pricing-body {
            li {
                color: var(--color-white);
            }
        }
    }
    &.pricing-borderd {
        border: var(--border-light);
    }
}

.pricing-billing-duration {
    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 60px;
        border-bottom: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .nav-item {
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
        }
        .nav-link {
            color: var(--color-body);
            font-weight: 500;
            background-color: transparent;
            border: none;
            padding: 0;
            &.active {
                color: var(--color-primary);
            }
        }
        .billed-check-box {
            display: flex;
            align-items: center;
            input {
                height: 0;
                width: 0;
                visibility: hidden;
            }
            label {
                cursor: pointer;
                width: 56px;
                height: 24px;
                background-color: var(--color-primary);
                border-radius: 12px;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    width: 16px;
                    height: 16px;
                    background-color: var(--color-white);
                    border-radius: 50%;
                    transition: var(--transition);
                }
            }
            input:checked + label:after {
                left: calc(100% - 4px);
                transform: translateX(-100%);
            }
        }
    }
}
