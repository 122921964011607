/*-------------------------
   Header Styles
-------------------------*/
.axil-header {
    position: relative;
    z-index: 4;
    .header-navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .header-logo {
            width: 100%;
            max-width: 180px;
            a {
                display: block;
            }
            .dark-version-logo {
                display: none;
            }
            .sticky-logo {
                display: none;
            }
            img {
                @media #{$md-layout} {
                    width: 180px;
                }
            }
        }
        .header-main-nav {
            flex: 1;
            margin: 0 55px 0 50px;
            @media #{$smlg-device} {
                margin: 0 30px;
            }
            @media #{$md-layout} {
                margin: 0;
            }
        }
    }
    &.header-style-1 {
        .axil-mainmenu {
            &.axil-sticky {
                 .header-navbar {
                    .header-logo {
                        .light-version-logo {
                            display: none;
                        }
                        .sticky-logo {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    &.header-style-2 {
        .axil-mainmenu {
            padding: 50px 80px;
            @media #{$laptop-device} {
                padding: 35px 15px;
            }
            @media #{$smlg-device} {
                padding: 35px 15px;
            }
            @media #{$large-mobile} {
                padding: 30px 0;
            }
            &.axil-sticky {
                padding: 15px 80px;
                @media #{$laptop-device} {
                    padding: 15px;
                }
                @media #{$smlg-device} {
                    padding: 15px;
                }
                @media #{$large-mobile} {
                    padding: 15px 0;
                }
            }
            .mainmenu {
                justify-content: center;
            }
        }
    }
    &.header-style-3 {
        .axil-mainmenu {
            &.axil-sticky {
                 .header-navbar {
                    .header-logo {
                        .light-version-logo {
                            display: none;
                        }
                        .sticky-logo {
                            display: block;
                        }
                    }
                }
            }
        }
        .header-action {
            .sidemenu-btn {
                .btn-wrap {
                    background-color: var(--color-light);
                }
            }
        }
    }
    &.header-style-4 {
        @media only screen and (max-width: 1299px) {
            .header-main-nav {
                margin: 0 15px;
            }
        }
        @media #{$md-layout} {
            .header-main-nav {
                margin: 0;
            }
        }
        .mainmenu-nav {
            @media #{$md-layout} {
                background-color: var(--color-dark);
            }
            .mainmenu {
                .menu-item-has-children {
                    .axil-submenu {
                        background-color: var(--color-dark);
                        li {
                            a {
                                color: var(--color-light);
                                &:hover {
                                    color: var(--color-primary);
                                }
                                &.active {
                                    color: var(--color-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
        .mobile-nav-header {
            border-color: var(--color-gray-4);
            .mobile-nav-logo {
                .light-mode {
                    display: none;
                }
                .dark-mode {
                    display: block;
                }
            }
            .mobile-menu-close {
                background-color: var(--color-white);
                color: var(--color-black);
                border: none;
            }
        }

        .axil-mainmenu {
            &.axil-sticky {
                background-color: var(--color-dark);
            }
        }
        .mainmenu {
            li {
                @media #{$smlg-device} {
                    margin: 0 10px;
                }
                @media #{$md-layout} {
                    border-color: var(--color-gray-4);
                }
                a {
                    color: var(--color-light);
                    &::before {
                        background-color: var(--color-white);
                    }
                    &::after {
                        color: var(--color-gray-4);
                    }
                }
            }
        }
        .header-action {
            .header-btn {
                .axil-btn {
                    display: block;
                }
            }
        }
    }
}


.axil-mainmenu {
    padding: 40px 0;
    transition: var(--transition);
    @media #{$laptop-device} {
        padding: 35px 0;
    }
    @media #{$smlg-device} {
        padding: 20px 0;
    }
    &.axil-sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        background-color: var(--color-white);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
        padding: 15px 0;
        animation: headerSlideDown .8s ease forwards;
        .header-action {
            .sidemenu-btn {
                .btn-wrap {
                    background-color: var(--color-body);
                    span {
                       background-color: var(--color-white);

                    }
                }
            }
            .social-icon-list {
                li {
                    a {
                        color: var(--color-dark);
                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}

// Main Nav

.mainmenu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
    >li {
        margin: 0 25px;
        @media #{$smlg-device} {
            margin: 0 12px;
        }
        >a {
            color: var(--color-body);
            font-weight: 500;
            font-size: var(--font-body-2);
            font-family: var(--font-primary);
            line-height: 60px;
            height: 60px;
            display: block;
            position: relative;
            transition: var(--transition);
            &::before {
                content: "";
                height: 2px;
                width: 0;
                background-color: var(--color-primary);
                position: absolute;
                bottom: 12px;
                left: 0;
                opacity: 0;
                transition: 0.5s;
            }
            &:hover {
                color: var(--color-primary);
                &::before {
                    opacity: 1;
                    width: 100%;
                }
            }
            &.active {
                color: var(--color-primary);
                &::before {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }
    >.menu-item-has-children {
        position: relative;
        >a {
            position: relative;
            margin-right: 20px;
            &::after {
                content: "\f107";
                font-family: var(--font-awesome);
                font-weight: 400;
                color: var(--color-text-dark);
                font-size: 18px;
                position: absolute;
                top: 0;
                right: -20px;
            }
        }
        .axil-submenu {
            position: absolute;
            top: 100%;
            left: 20px;
            background: #ffffff;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            min-width: 250px;
            padding: 15px 10px;
            border-radius: 4px;
            transition: var(--transition);
            list-style: none;
            margin: 0;
            box-shadow: 0 13px 48px 0 rgba(0, 0, 0, 0.15);
            li {
                margin: 0;
                transition: var(--transition);
                opacity: 0;
                transform: translateX(20px);
                a {
                    position: relative;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: var(--color-body);
                    font-weight: 500;
                    padding: 7px 15px;
                    border-radius: 4px;
                    display: block;
                    transition: var(--transition);
                    &::after {
                        content: "";
                        height: 1px;
                        width: 0;
                        background-color: var(--color-primary);
                        position: absolute;
                        top: 100%;
                        left: 0;
                        transform: translateY(-50%);
                        transition: var(--transition);
                    }
                    &:hover {
                        color: var(--color-primary);
                        &::after {
                            width: 100%;
                        }
                    }
                    &.active {
                        color: var(--color-primary);
                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
        &:hover {
            .axil-submenu {
                opacity: 1;
                visibility: visible;
                z-index: 9;
                left: 0;
                li {
                    opacity: 1;
                    transform: translateX(0);
                    &:nth-child(1n) {
                        transition-delay: .1s;
                    }
                    &:nth-child(2n) {
                        transition-delay: .2s;
                    }
                    &:nth-child(3n) {
                        transition-delay: .3s;
                    }
                    &:nth-child(4n) {
                        transition-delay: .4s;
                    }
                    &:nth-child(5n) {
                        transition-delay: .5s;
                    }
                    &:nth-child(6n) {
                        transition-delay: .6s;
                    }
                    &:nth-child(7n) {
                        transition-delay: .7s;
                    }
                    &:nth-child(8n) {
                        transition-delay: .8s;
                    }
                    &:nth-child(9n) {
                        transition-delay: .8s;
                    }
                    &:nth-child(10n) {
                        transition-delay: .8s;
                    }
                    &:nth-child(11n) {
                        transition-delay: .8s;
                    }
                    &:nth-child(12n) {
                        transition-delay: .8s;
                    }
                }
            }
        }
    }
}

// Header Action
.header-action {
    >ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        >li {
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .sidemenu-btn {
        margin-right: 0;
        .btn-wrap {
            height: 60px;
            width: 60px;
            background-color: var(--color-white);
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: var(--transition);
            @media #{$md-layout} {
                height: 50px;
                width: 50px;
            }
            span {
                height: 2px;
                width: 20px;
                background-color: var(--color-dark);
                margin-bottom: 4px;
                border-radius: 10px;
                transition: var(--transition);
                &:last-child {
                    margin-bottom: 0;
                    width: 10px;
                    margin-right: -10px;
                }
            }
            &:focus {
                box-shadow: none;
            }
            &:hover {
                span {
                    &:last-child {
                        width: 20px;
                        margin-right: 0;
                    }
                    &:first-child {
                        width: 10px;
                        margin-right: -10px;
                    }
                }
            }
            &.btn-dark {
                background-color: var(--color-body);
                span {
                    background-color: var(--color-white);
                }
            }
        }
    }
    .header-social-link {
        @media #{$large-mobile} {
            display: none;
        }
    }
    .social-icon-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -15px;
        li {
            margin: 15px;
            a {
                font-size: 20px;
                color: var(--color-body);
                @media #{$md-layout} {
                    color: var(--color-dark);
                }
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    .header-btn {
        margin-right: 0;
        @media only screen and (max-width: 1399px) {
            .axil-btn {
                padding: 15px 40px;
            }
        }
        @media #{$lg-layout} {
            .axil-btn {
                padding: 15px 25px;
            }
        }
        @media #{$md-layout} {
            margin-right: 30px;
        }

        @media #{$large-mobile} {
            display: none;
        }
    }
    .mobile-menu-btn {
        @media only screen and (min-width: 992px) {
            display: none;
        }
        .btn-wrap {
            height: 50px;
            width: 50px;
        }
    }
    .my_switcher {
        margin-left: 20px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        line-height: 52px;
        padding: 0;
        text-align: center;
        @media #{$sm-layout} {
            margin-left: 15px;
        }
       ul {
        justify-content: center;
       }
    }
}


// Offcanvas Menu
.header-offcanvasmenu {
    width: 300px;
    min-width: 300px;
    transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
     @media #{$smlg-device} {
        width: 70vw;
    }
    .offcanvas-header {
        padding: 50px 20px;
        @media #{$laptop-device} {
            padding: 50px 20px;
        }
        @media #{$smlg-device} {
            padding: 50px;
        }
        @media #{$sm-layout} {
            padding: 30px 20px 0;
        }
        .btn-close {
            margin: 0;
            font-size: 22px;
            transition: var(--transition);
            @media #{$md-layout} {
                font-size: 14px;
            }
            &:hover {
                color: var(--color-primary);
                opacity: 1;
            }
            &:focus {
                border: none;
                box-shadow: none;
            }
        }

    }
    .offcanvas-header {
        justify-content: end;
    }
    .offcanvas-body {
        padding: 50px 30px;
        @media #{$laptop-device} {
            padding: 50px;
        }
        @media #{$smlg-device} {
            padding: 50px;
        }
        @media #{$sm-layout} {
            padding: 30px;
        }
        .side-nav-search-form {
            position: relative;
            margin-bottom: 70px;
            @media #{$md-layout} {
                margin-bottom: 40px;
            }
            .search-field {
                font-size: 40px;
                height: auto;
                border: none;
                border-bottom: 2px solid var(--color-gray-3);
                border-radius: 0;
                padding: 5px 0;
                @media #{$md-layout} {
                    font-size: 20px;
                }
                &:focus {
                    box-shadow: none;
                }
            }
            .side-nav-search-btn {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: transparent;
                border: none;
                padding: 0;
                i {
                    font-weight: 400;
                    font-size: 24px;
                    color: var(--color-gray-4);
                    transition: var(--transition);
                    @media #{$md-layout} {
                        font-size: 18px;
                    }
                }
                &:hover {
                    i {
                        color: var(--color-primary);
                    }
                }
            }
        }
        .main-navigation {
            margin: -30px 0;
            margin-top: -45px;
            @media #{$md-layout} {
                margin-bottom: 40px;
            }
            @media #{$sm-layout} {
                margin: -20px 0;
                margin-bottom: 40px;
            }
            li {
                margin: 30px 0;
                @media #{$sm-layout} {
                    margin: 20px 0;
                }
                a {
                    color: var(--color-dark);
                    font-size: 36px;
                    font-weight: 500;
                    @media #{$smlg-device} {
                        font-size: 24px;
                    }
                    @media #{$sm-layout} {
                       font-size: 20px;
                    }
                    &:hover {
                        color: var(--color-primary);
                    }
                    &.active {
                        color: var(--color-primary);
                    }
                }
            }
        }
        .contact-inner {
            .title {
                font-size: 18px;
                line-height: 1;
                color: var(--color-dark);
                font-weight: 500;
                display: block;
                margin-bottom: 15px;
                @media #{$smlg-device} {
                    font-size: 16px;
                }
            }
            .address {
                margin-bottom: 40px;
                @media #{$md-layout} {
                    margin-bottom: 25px;
                }
                a {
                    color: var(--color-body);
                    display: block;
                    margin-bottom: 10px;
                    i {
                        margin-right: 20px;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
            .social-share {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin: -15px -6px;
                li {
                    margin: 15px 6px;
                    a {
                        color: var(--color-white);
                        font-size: 16px;
                        text-align: center;
                        width: 50px;
                        height: 50px;
                        line-height: 53px;
                        display: block;
                        border-radius: 100%;
                        background-color: var(--color-primary);
                        text-align: center;
                        &:hover {
                            background-color: var(--color-dark);
                        }
                    }
                }
            }
        }
    }
}


// Mobile Menu
.mobile-nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-fog);
    @media only screen and (min-width: 992px) {
        display: none;
    }
    .mobile-nav-logo {
        width: 162px;
        line-height: 1;
        a {
            display: block;
        }
        .dark-mode {
            display: none;
        }
    }
    .mobile-menu-close {
        background-color: transparent;
        height: 30px;
        width: 30px;
        border: 2px solid var(--color-gray-4);
        border-radius: 10px;
        color: var(--color-dark);
        font-size: 14px;
        transition: var(--transition);
        line-height: 1;
        &:hover {
            background-color: var(--color-primary);
            border-color: var(--color-primary);
            color: var(--color-white);
        }
    }
}


@media only screen and (max-width: 991px) {
    .axil-mainmenu {
        &.axil-sticky {
            animation: none;
        }
    }
    .mainmenu-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -300px;
        background-color: var(--color-white);
        width: 300px;
        padding: 20px 10px;
        transition: all 0.8s cubic-bezier(0.77, 0.2, 0.05, 1);
        z-index: 1050;
        &.show {
            left: 0;
            visibility: visible;
        }
        .mainmenu {
            display: block;
            height: 100%;
            overflow: auto;
            >li {
                border-bottom: 1px solid var(--color-fog);
                >a {
                    height: 50px;
                    line-height: 50px;
                    &::before {
                        display: none;
                    }
                }
            }
            .menu-item-has-children {
                a.rotate {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
                .axil-submenu {
                    display: none;
                    position: initial;
                    visibility: visible;
                    opacity: 1;
                    min-width: 100%;
                    box-shadow: none;
                    padding: 0 0 10px;
                    transition: initial;
                    &.show {
                        position: relative;
                        display: block;
                        left: 0;
                    }
                    li {
                        opacity: 1;
                        transform: translateX(0);
                        a {
                            padding: 7px 10px;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
                &.menu-item-open {
                    .axil-submenu {
                        display: block;

                    }
                }
            }
        }
    }
}


/// Keyfram Animation
@keyframes headerSlideDown {
    0% {
        margin-top: -50px;
        opacity: 0;
    }
    30% {
        margin-top: -10px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
div.isShowing#offcanvasMenuRight {
    visibility: visible;
    transform: translateX(0%);
}
.axil-sticky-placeholder {
    &.scrolled {
        height: 128px;
    }
    display: block;
    height: 0;
}
.axil-mainmenu .setColor {
    margin-top: 10px;
}
.light {
    display: none;
}
.dark {
    display: block;
}
.active-dark-mode .dark {
    display: none;
}
.active-dark-mode .light{
    display: block;
}
.active-light-mode .light {
    display: none;
}
.active-light-mode .dark {
    display: block;
}