/*-------------------------
    Slick 
-------------------------*/
.slick-list {
    margin: 0 -15px;
    .slick-slide {
        padding: 0 15px;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

// Slick Dotted Style

.slick-dot-nav {
    .slick-dots {
        bottom: 0;
        text-align: left;
        margin: 0 -10px;
        li {
            margin: 0 10px;
            height: 11px;
            width: 11px;
            button {
                border: none;
                background-color: #DBDEFF ;
                border-radius: 50%;
                height: 11px;
                width: 11px;
                transition: var(--transition);
                &::before {
                    display: none;
                }
            }
            &.slick-active {
                button {
                    background-color: var(--color-primary);
                }
            }
        }
    }
}

// Slick Nav Style

.slick-arrow-nav {
    .slick-arrow {
        height: 40px;
        width: 40px;
        background-color: rgba(0,0,0,0.2);
        border-radius: 50%;
        z-index: 1;
        transition: var(--transition);
        &::before {
            font-family: var(--font-awesome);
            opacity: 1;
            font-size: 16px;
        }
        &:hover {
            background-color: rgba(0,0,0,1);
        }
        &.slick-next {
            right: 50px;
            @media #{$sm-layout} {
                right: 20px;
            }

            &::before {
                content: "\f105";
            }
        }
        &.slick-prev {
            left: 50px;
            @media #{$sm-layout} {
                left: 20px;
            }
            &::before {
                content: "\f104";
            }
        }
    }
}