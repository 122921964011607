/*-------------------------
   Call To Action
-------------------------*/
.call-to-action-area {
    padding: 140px 0 220px;
    position: relative;
    z-index: 1;
    @media #{$smlg-device} {
       padding: 100px 0 195px;
    }
    @media #{$md-layout} {
       padding: 80px 0 170px;
    }
    @media #{$sm-layout} {
       padding: 60px 0 150px;
    }
    @media #{$large-mobile} {
       padding: 60px 0 100px;
    }
    &:before {
        content: "";
        height: 730px;
        width: 100%;
        background-color: var(--color-primary);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @media #{$md-layout} {
            height: 500px;
        }
        @media #{$sm-layout} {
            height: 480px;
        }
    }
}
.call-to-action {
    .section-heading {
        margin-bottom: 80px;
        @media #{$sm-layout} {
            margin-bottom: 60px;
        }
        span {
            color: var(--color-accent1);
        }
        .title {
            width: 60%;
            margin: 0 auto 55px;
            @media #{$md-layout} {
                width: 100%;
            }
        }
    }
    .thumbnail {
        position: relative;
        pointer-events: none;
        .larg-thumb {
            text-align: center;
            img {
                border-radius: 20px;
                @media #{$md-layout} {
                    width: 70%;
                }
                @media #{$large-mobile} {
                    width: 80%;
                }
            }
        }
        .small-thumb {
            li {
                position: absolute;
                img {
                    @media #{$smlg-device} {
                        width: 80%;
                    }
                    @media #{$md-layout} {
                        width: 60%;
                    }
                    @media #{$large-mobile} {
                        width: 50%;
                    }
                }
                &.shape-1 {
                    bottom: -50px;
                    left: 20px;
                    @media only screen and (min-width: 1200px) and (max-width: 1299px) {
                      left: -20px;  
                    }
                    @media #{$smlg-device} {
                        bottom: -30px;
                        left: -80px;
                    }
                    @media #{$large-mobile} {
                       display: none;
                    }
                }
                &.shape-2 {
                    bottom: -120px;
                    left: 34%;
                    @media #{$smlg-device} {
                        bottom: -100px;
                        left: 30%;
                    }
                    @media #{$sm-layout} {
                        bottom: -70px;
                    }
                    @media #{$large-mobile} {
                        bottom: -50px;
                    }
                }
                &.shape-3 {
                    bottom: -120px;
                    right: 20%;
                    @media only screen and (min-width: 1200px) and (max-width: 1299px) {
                      right: 15%;  
                    }
                    @media #{$smlg-device} {
                        bottom: -100px;
                        right: 15%;
                    }
                    @media #{$md-layout} {
                        right: 4%;
                    }
                    @media #{$sm-layout} {
                        right: 0;
                        bottom: -70px;
                    }
                    @media #{$large-mobile} {
                        bottom: -50px;
                        right: -35px;
                    }

                }
                &.shape-4 {
                    bottom: 20px;
                    right: 75px;
                    @media only screen and (min-width: 1200px) and (max-width: 1299px) {
                      right: 50px;  
                    }
                    @media #{$smlg-device} {
                        right: -60px; 
                    }
                    @media #{$md-layout} {
                        right: -90px;
                    }
                    @media #{$sm-layout} {
                        right: -110px;
                    }
                    @media #{$large-mobile} {
                        right: -130px;
                        bottom: -20px;
                    }
                }
                &.shape-5 {
                    top: 10px;
                    right: 115px;
                    @media #{$smlg-device} {
                        right: -70px; 
                    }
                    @media #{$md-layout} {
                        right: -130px;
                    }
                    @media #{$large-mobile} {
                        right: -210px;
                    }
                }
            }
        }
    }
}