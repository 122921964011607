/*-------------------------
    Why Choose
-------------------------*/
.why-choose-us {
    @media #{$md-layout} {
        margin-bottom: 60px;
    }
    .section-heading {
        margin-bottom: 50px;
        p {
            width: 100%;
        }
    }
    .accordion-item {
        border: none;
        border-bottom: 1px solid var(--color-gray-2);
        background-color: transparent;
        border-radius: 0;
        .accordion-button {
            font-size: 24px;
            color: var(--color-primary);
            background-color: transparent;
            box-shadow: none;
            padding: 20px 10px 20px 0;
            i {
                margin-right: 20px;
            }
            &:after {
                display: none;
            }
            &.collapsed {
                color: var(--color-body);
            }
        }
        .accordion-body {
            padding: 0 0 18px 0;
        }   
    }
    .why-choose-thumb {
        position: relative;
        z-index: 1;
        img {
            border-radius: 30px;
        }
    }
}